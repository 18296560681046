<template lang="pug">
  v-app
    //- Main content
    .foo.first
      .bar.inner
    .foo.second
    .bar.outer
    transition(name="auth-fade", mode="out-in")
      router-view
    notifications(
      group="kick",
      position="bottom right",
      classes="vue-notification")
      template(slot="body", slot-scope="props")
        .vue-notification
          v-layout
            .userpic(v-if="props.item.data.type !== 3")
              div(
                v-if="props.item.data.payload.photo_url",
                :style="{ 'background-image': 'url(' + avatarUrlWithResize(props.item.data.payload.photo_url, '32x32') + ')' }")
              AvatarPlaceholder.notification(
                v-else,
                :firstName="props.item.data.payload.first_name",
                :lastName="props.item.data.payload.last_name",
                :classname="getAvatarClass(props.item.data.payload.first_name, props.item.data.payload.last_name)")
            .message
              v-layout.message_head(align-center)
                .secondary_txt(:class="{ double: props.item.data.type === 2 }") {{ getNotificationTitle(props.item) }}
                ArrowThin(v-if="props.item.data.type === 2")
                .secondary_txt.double(v-if="props.item.data.type === 2") {{ props.item.data.payload.group_name }}
              .message_txt {{ getNotificationText(props.item) }}
              v-layout.message_foot(align-center)
                .secondary_txt {{ messageTime(props.item.data.created, $i18n.locale) }}
            .vue-notification_close(@click="closeNotification(props)")
              SvgPlus
    v-snackbar(v-model="snackBar", :timeout="snackBarTimeout", :multi-line="true") {{ snackBarText }}
      v-btn.snackbar_btn(color="blue", text, @click="snackBar = false") {{ $t('actions.close') }}
    v-dialog(v-model="showTermsModal", persistent, max-width="470")
      v-card.terms_modal
        .title {{ $t('termsModal') }}
        v-form(v-model="valid", ref="form")
          PolicyCheckboxes(:policy.sync="policy", :terms.sync="terms")
          v-btn.green(@click="acceptLatestTerms", :disabled="!valid || loading") {{ $t('actions.confirm2') }}
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TheHeader from '@/components/TheHeader';
import PolicyCheckboxes from '@/components/PolicyCheckboxes';
import SvgPlus from '@/components/svg/SvgPlus';
import ArrowThin from '@/components/svg/SvgArrowThin';
import WS from '@/ws';
import {
  SET_WS_STATE,
  SET_WS_AUTH,
  READ_NOTIFICATION,
  SIGNIN_CAPTCHA_REQUIRED
} from '@/store/modules/user/types';
import {
  storageIsAvailable,
  cookiesAreEnabled,
  avatarUrlWithResize,
  getAvatarClass, getCookie, setCookie
} from '@/utils';
import CONSTANTS from '@/constants';
import { messageTime } from '@/dates';
import { loadLanguageAsync } from '@/dictionary';

export default {
  name: 'App',
  components: {
    TheHeader,
    PolicyCheckboxes,
    SvgPlus,
    ArrowThin
  },
  data() {
    return {
      snackBar: false,
      snackBarTimeout: -1,
      snackBarText: '',
      languages: CONSTANTS.LANGUAGES,
      avatarUrlWithResize,
      getAvatarClass,
      showTermsModal: false,
      valid: false,
      terms: false,
      policy: false,
      loading: false
    };
  },
  methods: {
    messageTime(date, locale) {
      return messageTime(date, locale);
    },
    closeNotification(notification) {
      notification.close();

      if (!notification.item.data.id) return;

      WS.readNotification(notification.item.data.id);
      this.$store.commit(`user/${READ_NOTIFICATION}`, notification.item.data.id);
    },
    compareUserLanguageAndLocale(userLanguageId) {
      const lang = this.languages.find(i => i.id === userLanguageId);

      if (lang && lang.val !== this.$i18n.locale) {
        loadLanguageAsync(lang.val);
      }
    },
    getNotificationTitle({ title, data: { type, payload } }) {
      return type === 3 ? title : `${payload.first_name} ${payload.last_name}`;
    },
    getNotificationText({ text, data: { type, payload } }) {
      return type === 3 ? text : payload.text;
    },
    acceptLatestTerms() {
      if (!this.$refs.form.validate() || this.loading) return;

      this.loading = true;

      const payload = {
        data: {
          type: 'user-profile-internal',
          attributes: {
            terms_version: this.latestVersionTerms.id,
            privacy_version: this.latestVersionPolicy.id
          }
        }
      };

      this.$store.dispatch('user/updateProfile', payload)
        .then(() => {
          this.showTermsModal = false;
          this.$store.dispatch('user/getUser');
        })
        .catch(errors => console.error(errors));
    },
    identifyUserForUsedesk() {
      let token = getCookie('usedeskToken');

      window.usedeskMessenger.identify({
        ...(token && { token }),
        name: `${this.user.first_name} ${this.user.last_name}`,
        email: this.user.email,
        phone: this.user.id
      });

      window.__usedeskWidgetInitedCallback = () => {
        if (token) return;

        token = window.usedeskMessenger.getChatToken();

        setCookie('usedeskToken', token, {
          domain: '.kickex.com',
          expires: new Date('2100')
        });
      };
    }
  },
  computed: {
    ...mapGetters('user', {
      user: 'getUser',
      loggedIn: 'loggedIn'
    }),
    ...mapState('user', [
      'acceptedLastVersions',
      'latestVersionTerms',
      'latestVersionPolicy'
    ]),
    WSopen() {
      return this.$store.state.user.WSopen;
    },
    rules() {
      return {
        required: v => !!v || this.$t('validation.required')
      };
    }
  },
  watch: {
    loggedIn(val) {
      if (val) {
        this.compareUserLanguageAndLocale(this.user.language_id);
        WS.init();
      } else {
        WS.close(1000);
      }
    },
    WSopen(val) {
      if (val && localStorage.getItem('access_token')) {
        WS.authenticate(localStorage.getItem('access_token'));
      }
    },
    acceptedLastVersions(val) {
      if (val) return;

      this.showTermsModal = true;
    },
    // eslint-disable-next-line func-names
    'user.id': function () {
      this.identifyUserForUsedesk();
    }
  },
  async mounted() {
    if (!storageIsAvailable('localStorage') || !cookiesAreEnabled()) {
      this.snackBar = true;
      this.snackBarText = this.$t('hints.enableCookies');

      return;
    }

    this.$eventHub.$on('WSopened', () => this.$store.commit(`user/${SET_WS_STATE}`, true));
    this.$eventHub.$on('WSclosed', () => this.$store.commit(`user/${SET_WS_STATE}`, false));
    this.$eventHub.$on('WSauth', () => this.$store.commit(`user/${SET_WS_AUTH}`, true));
    this.$eventHub.$on('captchaRequested', () => this.$store.commit(`user/${SIGNIN_CAPTCHA_REQUIRED}`, true));
  },
  beforeDestroy() {
    this.$eventHub.$off('WSopened');
    this.$eventHub.$off('WSclosed');
    this.$eventHub.$off('WSauth');
    this.$eventHub.$off('captchaRequested');
  }
};
</script>

<style lang="scss">
@import './assets/scss/variables';

#app {
  background: $white !important;
  font-family: Gilroy, Helvetica, Roboto, Arial, sans-serif !important;
  transition: opacity .5s $easing;
}

.terms_modal {
  padding: 42px 35px;
  text-align: left;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .v-btn {
    width: 100%;
    margin-top: 10px;

    &.theme--light.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $light-theme-text !important;
      color: $white !important;
    }
  }
}

.auth-fade-enter-active,
.auth-fade-leave-active {
  transition: all .3s $easing;
}

.auth-fade-enter,
.auth-fade-leave-to {
  opacity: 0;
}
</style>
