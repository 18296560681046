/**
 * если нужны будут доп фичи vuex - можно почитать про классные плагины тут: https://medium.com/devschacht/%D0%BF%D1%8F%D1%82%D1%8C-%D0%BF%D0%BB%D0%B0%D0%B3%D0%B8%D0%BD%D0%BE%D0%B2-vuex-f0ba8370b0d5
 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/axios';
import jsonApi from '@/jsonApi';
import i18n from '@/dictionary';
import userActions from './modules/user/actions';
import userGetters from './modules/user/getters';
import userMutations from './modules/user/mutations';

Vue.use(Vuex);

const data = {
  modules: {
    user: {
      id: null,
      loggedIn: false,
      welcomeImgName: '',
      first_name: '',
      last_name: '',
      registration_country_id: null,
      email: null,
      errorMessages: [],
      loadingProfile: false,
      refreshingToken: false,
      resolvingRoute: false,
      recoverySentTimestamp: null,
      confirmationSentTimestamp: null,
      recoverySent: null,
      WSauth: false,
      WSopen: false,
      WSunreadNotifications: [],
      emailChangeErrors: [],
      signinCaptchaRequired: false,
      captchaId: null,
      captchaImage: null,
      socialConnectErrors: null,
      socialSignInErrors: [],
      resetPasswordHashErrors: null,
      kycSessionErrors: null,
      countries: [],
      latestVersionTerms: null,
      latestVersionPolicy: null,
      acceptedLastVersions: true,
      is_country_high_risk: false,
      is_country_restricted: false,
      redirectUrlAfterKyc: null,
      registrationType: null
    }
  }
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user: {
      namespaced: true,
      state: data.modules.user,
      actions: userActions,
      getters: userGetters,
      mutations: userMutations
    }
  }
});

const replaceAttributeInError = (attrArr, error, errorPropName) => {
  attrArr.forEach(item => {
    const attrValue = error.source.parameter.slice(error.source.parameter.lastIndexOf('.') + 1);

    error[errorPropName] = error[errorPropName].replace(item.trim(), attrValue);
  });
};

const replaceParamsInError = (paramsArr, error, errorPropName) => {
  paramsArr.forEach((attr, index) => {
    // type of error.meta.parameters can be Object or Array
    if (Array.isArray(error.meta.parameters)) {
      error[errorPropName] = error[errorPropName].replace(attr.trim(), error.meta.parameters[index]);
    }

    if (typeof error.meta.parameters === 'object') {
      const objKey = attr.slice(2);

      error[errorPropName] = error[errorPropName].replace(attr.trim(), error.meta.parameters[objKey]);
    }
  });
};

const replacePlaceholdersInError = (placeholdersArr, error, errorPropName) => {
  const attrs = placeholdersArr.filter(i => i.trim() === ':attribute');
  const params = placeholdersArr.filter(i => i.trim() !== ':attribute');

  if (attrs.length) replaceAttributeInError(attrs, error, errorPropName);

  if (params.length) replaceParamsInError(params, error, errorPropName);
};

// get :placeholders from errors' titles and details and replace them with their values from error meta fields
const transformValidationErrors = errorsArr => errorsArr.map(error => {
  if (error.code) {
    const translatedError = {
      code: error.code,
      title: i18n.te(`errorCodes.${error.code}.title`) ? i18n.t(`errorCodes.${error.code}.title`) : error.title,
      detail: i18n.te(`errorCodes.${error.code}.detail`) ? i18n.t(`errorCodes.${error.code}.detail`) : error.detail
    };

    return translatedError;
  }

  const titleMatches = error.title.match(/\s:[a-zA-Z0-9]+/g);
  const detailMatches = error.detail.match(/\s:[a-zA-Z0-9]+/g);

  if (titleMatches) replacePlaceholdersInError(titleMatches, error, 'title');
  if (detailMatches) replacePlaceholdersInError(detailMatches, error, 'detail');

  return error;
});

const checkIfCaptchaRequested = errorsArr => errorsArr.some(error => error.source && error.source.parameter && error.source.parameter.includes('captcha_id'));

// interceptor
const interceptor = error => {
  if (error.response && error.response.status === 401 && error.response.config.model === 'refresh-tokens') {
    window.location.replace(`/signin${window.location.search}`);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('session_id');
  }

  if (error.config && error.response && error.response.status === 401) {
    return store.dispatch('user/refreshToken').then(res => {
      error.config.headers.Authorization = `Bearer ${res.access_token}`;

      return axios.request(error.config);
    });
  }

  if (error.response && error.response.data && error.response.data.errors) {
    if ([400, 422].includes(error.response.status) && checkIfCaptchaRequested(error.response.data.errors)) {
      Vue.prototype.$eventHub.$emit('captchaRequested');
    }

    return Promise.reject(transformValidationErrors(error.response.data.errors));
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(response => response, error => interceptor(error));
jsonApi.axios.interceptors.response.use(response => response, error => interceptor(error));

export default store;
