export default {
  SERVICES_ID: Object.freeze({
    admin: 0,
    kickico: 1,
    kickicoadmin: -1,
    whirl: 2,
    kickex: 3,
    kickref: 4,
    kickrefadmin: -4,
    kickwallet: 5,
    kickmessenger: 6,
    kickexdemo: 7,
    aml: 8
  }),
  SERVICES_NAMES: Object.freeze({
    0: 'KickID',
    1: 'KickICO',
    2: 'WHIRL',
    3: 'KickEX',
    4: 'KickREF',
    5: 'KickWallet',
    6: 'Kick Messenger',
    7: 'KickEX Demo'
  }),
  AUTHENTICATOR_LINKS: Object.freeze({
    android: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    iOS: 'https://itunes.apple.com/app/google-authenticator/id388497605?mt=8',
    windowsPhone: 'https://www.microsoft.com/en-us/store/top-free/apps/mobile'
  }),
  SERVICES_ORIGINS: [
    'http://local',
    '.stage.kickex.com', // for QA test every branch (https://BAC-1234.stage.kickex.com)

    'https://dev.ref.kickex.com',
    'https://stage.ref.kickex.com',
    'https://ref.kickex.com',

    'https://dev.wallet.kickico.com',
    'https://stage.wallet.kickico.com',
    'https://wallet.kickico.com',

    'https://dev.kickex.com',
    'https://stage.kickex.com',
    'https://demo.kickex.com',
    'https://prod.kickex.com',
    'https://kickex.com',

    'https://dev.admin.kickex.com',
    'https://stage.admin.kickex.com',
    'https://admin.kickex.com',

    'https://dev.messenger.kickex.com',
    'https://stage.messenger.kickex.com',
    'https://messenger.kickex.com',

    'https://dev.kickico.com',
    'https://stage.kickico.com',
    'https://kickico.com'
  ],
  LANGUAGES: Object.freeze([
    { id: 1, val: 'en', name: 'English' },
    { id: 5, val: 'ru', name: 'Русский' },
    { id: 6, val: 'zh', name: '简体中文' },
    { id: 15, val: 'tr', name: 'Türkçe' },
    { id: 9, val: 'vi', name: 'Tiếng Việt' },
    { id: 8, val: 'ko', name: '한국어' },
    { id: 14, val: 'ja', name: '日本語' },
    { id: 17, val: 'hi', name: 'हिन्दी' },
    { id: 11, val: 'id', name: 'Indonesian' },
    // { id: 7, val: 'zhtw', name: '繁體中文' },
    { id: 3, val: 'es', name: 'Español' },
    { id: 4, val: 'pt', name: 'Português' },
    { id: 13, val: 'th', name: 'ไทย' },
    { id: 12, val: 'de', name: 'Deutsch' },
    { id: 2, val: 'fr', name: 'Français' },
    { id: 10, val: 'it', name: 'Italiano' },
    { id: 16, val: 'pl', name: 'Polski' },
    { id: 18, val: 'ar', name: 'العربية' }
  ]),
  SERVICES_MENU: [
    {
      name: 'KickEX',
      short: 'ex',
      id: 3,
      status: 3,
      url: process.env.VUE_APP_KICKEX,
      // beta: true,
      visible: true,
      gtm_index: 'kex'
    },
    {
      name: 'KickEx [prod]',
      short: 'ex',
      id: 7,
      status: 3,
      url: process.env.VUE_APP_KICKEX_PROD,
      beta: true,
      visible: false,
      gtm_index: 'kex'
    },
    {
      name: 'KickRef',
      short: 'ref',
      id: 4,
      status: 3,
      url: process.env.VUE_APP_KICKREF,
      visible: true,
      gtm_index: 'ref'
    },
    {
      name: 'KickMessenger',
      short: 'messenger',
      id: 6,
      status: 3,
      url: process.env.VUE_APP_MESSENGER,
      visible: true,
      gtm_index: ''
    },
    {
      name: 'KickWallet',
      short: 'wallet',
      id: 5,
      status: 3,
      url: process.env.VUE_APP_WALLET,
      visible: true,
      alpha: false,
      gtm_index: 'wal'
    },
    {
      name: 'KickICO',
      short: 'ico',
      id: 1,
      status: 3,
      url: process.env.VUE_APP_KICKICO,
      visible: true,
      gtm_index: ''
    },
    {
      name: 'KickMobile',
      short: 'mobile',
      id: null,
      status: 1,
      url: null,
      visible: true,
      gtm_index: ''
    },
    {
      name: 'KickPAY',
      short: 'pay',
      id: 1,
      status: 1,
      url: null,
      visible: true,
      gtm_index: ''
    },
    {
      name: 'KickCPA',
      short: 'cpa',
      id: 1,
      status: 1,
      url: null,
      visible: true,
      gtm_index: ''
    },
    {
      name: 'KickDesk',
      short: 'desk',
      id: null,
      status: 1,
      url: null,
      visible: true,
      gtm_index: ''
    }
  ],
  UTM_PARAMS: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utmstat'
  ]
};
