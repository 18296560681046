import Vue from 'vue';
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import Vuetify, {
  VApp,
  VLayout,
  VRow,
  VCol,
  VFlex,
  VAvatar,
  VForm,
  VBtn,
  VIcon,
  VCheckbox,
  VTextField,
  VSelect,
  VAutocomplete,
  VDialog,
  VTab,
  VTabs,
  VCard,
  VCardTitle,
  VCardText,
  VProgressLinear,
  VSlider,
  VMenu,
  VList,
  VListItem,
  VSimpleTable,
  VSnackbar,
  VPagination,
  VRadioGroup,
  VRadio,
  VSubheader,
  VProgressCircular,
  VDatePicker,
  VSwitch
} from 'vuetify/lib';
import Croppa from 'vue-croppa';
import Notifications from 'vue-notification';
import 'vue-croppa/dist/vue-croppa.css';
import App from '@/App';
import router from '@/router';
import i18n from '@/dictionary';
import store from '@/store/store';
import CONSTANTS from '@/constants';
import { getUnauthUserLocale } from '@/utils';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/scss/app.scss';
import * as packageJson from '../package.json';

// Sentry.init({
//   beforeSend(event) {
//     if (process.env.VUE_APP_ENV === 'local') return null;
//     return event;
//   },
//   environment: process.env.VUE_APP_ENV,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })]
// });
// Sentry.configureScope(scope => {
//   scope.setExtra('width', window.innerWidth);
//   scope.setExtra('height', window.innerHeight);
//   scope.setExtra('browserLocale', window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : 'en');
// });

Vue.use(Vuetify, {
  components: {
    VApp,
    VLayout,
    VRow,
    VCol,
    VFlex,
    VAvatar,
    VForm,
    VBtn,
    VIcon,
    VCheckbox,
    VTextField,
    VSelect,
    VAutocomplete,
    VDialog,
    VTab,
    VTabs,
    VCard,
    VCardTitle,
    VCardText,
    VProgressLinear,
    VSlider,
    VMenu,
    VList,
    VListItem,
    VSimpleTable,
    VSnackbar,
    VPagination,
    VRadioGroup,
    VRadio,
    VSubheader,
    VProgressCircular,
    VDatePicker,
    VSwitch
  }
});
Vue.use(Croppa);
Vue.use(Notifications);

Vue.config.devtools = process.env.VUE_APP_DEV === 'true'; // Toggle VueDevTools plugin
Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue(); // Global EventBus

new Vue({
  i18n,
  router,
  store,
  vuetify: new Vuetify({ icons: { iconfont: 'mdi' } }),
  render: h => h(App),
  created() {
    console.info(`%cKickID | v${packageJson.default.version}`, 'color: #fff; font-weight: bold; background: #47c; padding:3px 5px;');

    const language = getUnauthUserLocale();
    const curLang = CONSTANTS.LANGUAGES.find(lang => lang.val === language);

    if (curLang) {
      i18n.locale = curLang.val;
    } else {
      localStorage.removeItem('language');
    }
  }
}).$mount('#app');
