<template lang="pug">
  header(:class="{ small, dark }")
    v-layout.wrapper(justify-space-between, align-center)
      //- router-link.logo(to="/")
      Logo(serviceName="ex")
      span
        v-layout
          v-menu(v-model="showLangMenu", left, bottom, offset-y, nudge-bottom="20", max-height="70%")
            template(v-slot:activator="{ on }")
              v-layout.lang_select(v-on="on", align-center)
                .flag(:class="currentLang.val")
                span {{ currentLang.name }}
            v-list.lang__list
              v-subheader {{ $t('actions.chooseLanguage') }}
              v-list-item.lang__item(v-for="lang in langs", :key="lang.id", @click="changeLanguage(lang)")
                .flag(:class="lang.val")
                | {{ lang.name }}
</template>

<script>
import Logo from '@/components/Logo';
import AvatarPlaceholder from '@/components/AvatarPlaceholder';
import { SET_WS_UNREAD_NOTIFICATIONS } from '@/store/modules/user/types';
import getAvatarUrlWithResize from '@/utils';
import CONSTANTS from '@/constants';
import { loadLanguageAsync } from '@/dictionary';

export default {
  components: { Logo, AvatarPlaceholder },
  data() {
    return {
      avatarUrl: '',
      firstName: '',
      lastName: '',
      getAvatarUrlWithResize,
      scrolled: false,
      small: false,
      dark: true,
      showLangMenu: false,
      langs: CONSTANTS.LANGUAGES
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    changeLanguage({ val, id }) {
      if (this.$i18n.locale === val) return;

      localStorage.setItem('language', val);
      loadLanguageAsync(val);

      if (this.user.loggedIn) {
        const payload = {
          data: {
            type: 'user-profile-internal',
            attributes: {
              language_id: id
            }
          }
        };

        this.$store.dispatch('user/updateProfile', payload)
          .then(() => {
            this.$store.dispatch('user/getUser');
          })
          .catch(errors => console.error(errors));
      }
    }
  },
  mounted() {
    this.$eventHub.$on('WShasUnreadNotifications', notifications => {
      this.$store.commit(`user/${SET_WS_UNREAD_NOTIFICATIONS}`, notifications);
    });

    this.avatarUrl = this.user.avatar_url;
    this.lastName = this.user.last_name;
    this.firstName = this.user.first_name;
  },
  computed: {
    user() { return this.$store.state.user; },
    currentLang() {
      return this.langs.find(lang => lang.val === this.$i18n.locale);
    }
  },
  watch: {
    user: {
      handler(val) {
        if (val.loggedIn) {
          this.avatarUrl = val.avatar_url;
          this.lastName = val.last_name;
          this.firstName = val.first_name;
        }
      },
      deep: true
    },
    scrolled(value) {
      this.small = value > 0;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss">
@import '../assets/scss/variables';

header {
  position: fixed;
  top: 0;
  padding-top: 40px;
  z-index: 10;
  width: 100%;
  transition: all .5s $easing;

  &.small {
    padding: 10px 0;

    &.dark {
      background-color: $light-theme-primary-text;

      .descriptor,
      .lang_select {
        color: $white !important;
      }
    }

    @supports (backdrop-filter: blur(3px) saturate(400%) brightness(0.7)) {
      &.dark {
        background-color: $light-theme-secondary-text;
        backdrop-filter: blur(10px) saturate(400%) brightness(0.7);
      }
    }
  }

  a { text-decoration: none; }

  .lang_select {
    font-size: 14px !important;
    font-weight: 600;
    color: $black;
    cursor: pointer;
  }

  .avatar_link {
    margin-left: auto;
    margin-right: 15px;
  }

  .avatar {
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    height: 30px;
    transition: all .2s $easing;
    width: 30px;
    min-width: 30px !important;

    &:hover {
      box-shadow: 1px 2px 4px $light-theme-22-text;
      transform: scale(1.2);
    }
  }

  .wrapper {
    @media screen and (max-width: 1264px) {
      max-width: 1140px;
      padding: 0 20px;
    }
  }
}

  @media screen and (max-width: 600px) {
    .descriptor { font-size: 15px !important; };

    .logo {
      svg {
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
      }
    }
  }
</style>
