<template lang="pug">
  v-avatar(:class="classname")
    span {{ getChars(fullName).toUpperCase() }}
</template>

<script>
export default {
  props: {
    classname: { type: String, default: 'avatar-1' },
    fullName: { type: String, required: true }
  },
  methods: {
    getColor() {
      return 'blue';
    },
    getChars(fullName) {
      return fullName.split(' ').splice(0, 2).map(item => item[0]).join('');
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  @each $color in $avatar-colors {
      $i: index($avatar-colors, $color);

      .avatar-#{$i} {
        background: $avatar-gradient, $color;
      }
  }

  .v-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: $white;
    font-weight: 600;
  }

  .lg {
    width: 120px !important;
    height: 120px !important;

    span {
      font-size: 40px;
      padding-top: 8px;
    }
  }

  .md {
    width: 80px !important;
    height: 80px !important;

    span {
      font-size: 28px;
      padding-top: 5px;
    }

  }

  .sm {
    width: 50px !important;
    height: 50px !important;

    span {
      font-size: 14px;
      padding-top: 4px;
    }
  }

  .xs {
    width: 30px !important;
    height: 30px !important;

    span {
      font-size: 14px;
      padding-top: 3px;
    }
  }
</style>
