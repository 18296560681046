// import { getUnauthUserLocale } from '@/utils';
import {
  SET_USER,
  RECOVERY_ERROR,
  RECOVERY_SENT,
  ACCOUNT_ACTIVATED,
  ACTIVATION_ERROR,
  SET_PASSWORD_KEY,
  PASSWORD_CHANGED,
  PASSWORD_CHANGE_ERROR,
  PROFILE_LOADING,
  USER_LOGOUT,
  REFRESHING_TOKEN,
  RESOLVING_ROUTE,
  SET_WS_STATE,
  SET_WS_AUTH,
  SET_WS_UNREAD_NOTIFICATIONS,
  READ_NOTIFICATION,
  CONFIRMATION_RESENT,
  EMAIL_CHANGE_ERRORS,
  EMAIL_CHANGE_SUCCESS,
  SIGNIN_CAPTCHA_REQUIRED,
  SET_CAPTCHA_ID,
  SET_CAPTCHA_IMAGE,
  SOCIAL_CONNECT_ERRORS,
  SOCIAL_SIGNIN_ERRORS,
  SET_RESET_PASSWORD_HASH_ERRORS,
  KYC_SESSION_ERRORS,
  SET_COUNTRIES,
  SET_TERMS_LATEST_VERSIONS,
  SET_TERMS_ACCEPTANCE_STATUS,
  SET_REDIRECT_URL_KYC,
  SET_IMG_NAME
} from '@/store/modules/user/types';

export default {
  [SET_USER]: (state, data) => {
    state.id = data.id;
    state.loggedIn = true;
    Object.assign(state, data.attributes);
  },
  [RECOVERY_ERROR]: state => {
    state.recoveryError = true;
  },
  [RECOVERY_SENT]: (state, email) => {
    state.recoverySent = email;
    state.recoverySentTimestamp = Date.now();
    state.recoveryError = false;
  },
  [ACCOUNT_ACTIVATED]: state => {
    state.activated = true;
  },
  [ACTIVATION_ERROR]: (state, errors) => {
    state.error = true;
    state.errorMessages = errors;
  },
  [SET_PASSWORD_KEY]: (state, key) => {
    state.passwordKey = key;
  },
  [PASSWORD_CHANGED]: state => {
    state.passwordChanged = true;
  },
  [PASSWORD_CHANGE_ERROR]: (state, errors) => {
    state.passwordChanged = false;
    state.errorMessages = errors;
  },
  [PROFILE_LOADING]: state => {
    state.loadingProfile = !state.loadingProfile;
  },
  [SET_IMG_NAME]: (state, payload) => {
    state.welcomeImgName = payload;
  },
  [USER_LOGOUT]: state => {
    state.id = null;
    state.loggedIn = false;
    state.error = false;
    state.errorMessages = [];
    state.loadingProfile = false;
    state.refreshingToken = false;
    state.resolvingRoute = false;
    state.recoverySentTimestamp = null;
    state.confirmationSentTimestamp = null;
    state.recoverySent = null;
    state.WSunreadNotifications = [];
    state.emailChangeErrors = [];
    state.signinCaptchaRequired = false;
    state.captchaId = null;
    state.captchaImage = null;
    state.socialConnectErrors = null;
    state.latestVersionTerms = null;
    state.latestVersionPolicy = null;
  },
  [REFRESHING_TOKEN]: state => {
    state.refreshingToken = !state.refreshingToken;
  },
  [RESOLVING_ROUTE]: (state, data) => {
    state.resolvingRoute = data;
  },
  [SET_WS_STATE]: (state, data) => {
    state.WSopen = data;
  },
  [SET_WS_AUTH]: (state, data) => {
    state.WSauth = data;
  },
  [SET_WS_UNREAD_NOTIFICATIONS]: (state, notifications) => {
    state.WSunreadNotifications = notifications;
  },
  [READ_NOTIFICATION]: (state, id) => {
    const readItem = state.WSunreadNotifications.find(i => i.id === id);

    state.WSunreadNotifications.splice(state.WSunreadNotifications.indexOf(readItem), 1);
  },
  [CONFIRMATION_RESENT]: state => {
    state.confirmationSentTimestamp = Date.now();
  },
  [EMAIL_CHANGE_ERRORS]: (state, errors) => {
    state.emailChangeErrors = errors;
  },
  [EMAIL_CHANGE_SUCCESS]: state => {
    state.emailChangeErrors = [];
  },
  [SIGNIN_CAPTCHA_REQUIRED]: (state, value) => {
    state.signinCaptchaRequired = value;

    if (!value) {
      state.captchaId = null;
      state.captchaImage = null;
    }
  },
  [SET_CAPTCHA_ID]: (state, id) => {
    state.captchaId = id;
  },
  [SET_CAPTCHA_IMAGE]: (state, base64) => {
    state.captchaImage = base64;
  },
  [SOCIAL_CONNECT_ERRORS]: (state, data) => {
    state.socialConnectErrors = data;
  },
  [SOCIAL_SIGNIN_ERRORS]: (state, data) => {
    state.socialSignInErrors = data;
  },
  [SET_RESET_PASSWORD_HASH_ERRORS]: (state, data) => {
    state.resetPasswordHashErrors = data;
  },
  [KYC_SESSION_ERRORS]: (state, data) => {
    state.kycSessionErrors = data;
  },
  [SET_COUNTRIES]: (state, data) => {
    state.countries = data;
  },
  [SET_TERMS_LATEST_VERSIONS]: (state, data) => {
    state.latestVersionTerms = data.terms;
    state.latestVersionPolicy = data.policy;
  },
  [SET_TERMS_ACCEPTANCE_STATUS]: (state, data) => {
    state.acceptedLastVersions = data;
  },
  [SET_REDIRECT_URL_KYC]: (state, data) => {
    state.redirectUrlAfterKyc = data;
  },
  setRegistrationType: (state, socialName = '') => {
    const registrationTypes = {
      email: 1,
      google: 2,
      facebook: 3,
      apple: 4
    };

    state.registrationType = registrationTypes[socialName];
  }
};
