import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';
import i18n from '@/dictionary';
import {
  SET_PASSWORD_KEY,
  RESOLVING_ROUTE,
  USER_LOGOUT,
  EMAIL_CHANGE_ERRORS,
  SOCIAL_CONNECT_ERRORS,
  SET_RESET_PASSWORD_HASH_ERRORS,
  SOCIAL_SIGNIN_ERRORS
} from '@/store/modules/user/types';
import CONSTANTS from '@/constants';

Vue.use(Router);
// https://stage.id.kickex.com/auth/kickex?redirectUrl=https://stage.kickex.com/en/buy-crypto&signin&warning=avan
// set as referrer KICK services only
const checkAndSetReferrer = to => {
  if (to.query.fromUrl) {
    sessionStorage.setItem('fromUrl', to.query.fromUrl);
  }

  if (to.query.redirectUrl) {
    localStorage.setItem('referrer', to.query.redirectUrl);
    console.log('get refferer', localStorage.getItem('referrer'));
    delete to.query.redirectUrl;
    return;
  }

  const { search, origin } = new URL(document.referrer);
  let referrerStr = document.referrer;

  if (search.includes('accessToken') || search.includes('refreshToken')) {
    referrerStr = referrerStr.slice(0, referrerStr.indexOf('?'));
  }

  const isReferrerKickService = CONSTANTS.SERVICES_ORIGINS.some(item => {
    if (process.env.VUE_APP_ENV === 'stage') return origin.includes(item);
    return origin.startsWith(item);
  });

  if (window.location.origin !== origin && isReferrerKickService) {
    localStorage.setItem('referrer', referrerStr);
  }
};

const checkAndSetUTMParams = queryParams => {
  let hasUTM = false;

  CONSTANTS.UTM_PARAMS.forEach(key => {
    if (queryParams[key]) {
      hasUTM = true;
      localStorage.setItem(key, queryParams[key]);
      delete queryParams[key];
    }
  });

  return hasUTM;
};

// check if a user is authenticated (add this method to 'beforeEnter' property of the protected routes)
function requireAuth(to, from, next) {
  if (to.name === 'settings-social-connect') next();

  if (store.state.user.loggedIn || (localStorage.getItem('access_token') && localStorage.getItem('refresh_token') && !store.state.user.loadingProfile)) {
    store.dispatch('user/getUser')
      .then(() => next())
      .catch(() => next({ name: 'signin', replace: true }));
  } else {
    console.log(`%cRoute "${to.path}" is protected. Sign in to proceed`, 'color: #fff; font-weight: bold; background: #ed3434; padding:3px 5px;');
    next({ name: 'signin', replace: true });
  }

  store.watch(() => store.state.user.loggedIn, () => {
    if (store.state.user.loggedIn) next();
  });
}

// redirect user with tokens to /settings
function redirectIfHasToken(to, from, next) {
  if (!document.referrer) {
    // localStorage.removeItem('referrer');
    // localStorage.removeItem('service_id');
  }

  if ((!localStorage.getItem('access_token') && !localStorage.getItem('refresh_token') && !store.state.user.loadingProfile) || to.params.signIn) {
    next();
    return;
  }

  store.dispatch('user/getUser')
    .then(() => next({ path: '/settings', replace: true }))
    .catch(() => next());
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'auth',
      props: true,
      component: () => import('@/views/AuthHome'),
      meta: { title: `${i18n.t('pageTitles.signup')} - KickID` },
      redirect: { name: 'signup' },
      children: [
        {
          path: 'signup',
          name: 'signup',
          props: true,
          component: () => import('@/components/AuthSignUp'),
          meta: { title: `${i18n.t('pageTitles.signup')} - KickID` },
          beforeEnter: redirectIfHasToken
        },
        {
          path: 'signin',
          name: 'signin',
          props: true,
          component: () => import('@/components/AuthSignIn'),
          meta: { title: `${i18n.t('pageTitles.signin')} - KickID` },
          beforeEnter: redirectIfHasToken
        },
        {
          path: 'confirm-email',
          name: 'confirm-email',
          props: true,
          component: () => import('@/components/EmailConfirm'),
          meta: { title: `${i18n.t('pageTitles.confirmEmail')} - KickID` },
          beforeEnter: requireAuth
        },
        {
          path: '/social/:socialName',
          name: 'social-auth',
          component: () => import('@/components/AuthRegisteredSocial'),
          props: true,
          beforeEnter: (to, from, next) => {
            if (!!to.query.error || !!to.query.error_code || !!to.query.error_message) {
              store.commit(`user/${SOCIAL_SIGNIN_ERRORS}`, [{ title: i18n.t('messages.error.undefined'), detail: i18n.t('messages.error.socialSignInGeneralError') }]);
              next('/signin');
            } else {
              next();
            }
          }
        },
        {
          path: 'complete-signup',
          name: 'complete-signup',
          props: true,
          component: () => import('@/components/CompleteSignup'),
          meta: { title: `${i18n.t('pageTitles.signin')} - KickID` },
          beforeEnter: requireAuth
        },
        {
          path: 'restore',
          name: 'restore',
          component: () => import('@/components/AuthPasswordRestore'),
          props: true
        },
        {
          path: 'block',
          name: 'blocked',
          meta: { title: `${i18n.t('pageTitles.blockAccount')} - KickID` },
          component: () => import('@/components/BlockedAccount'),
          children: [
            {
              path: ':code',
              name: 'block-account',
              props: true,
              beforeEnter: (to, from, next) => {
                store.dispatch('user/blockAccount', to.params.code)
                  .then(() => next({ name: 'blocked', replace: true }))
                  .catch(() => next({ name: 'auth', replace: true }));
              }
            }
          ]
        }
      ]
    },
    {
      path: '/auth/:service',
      name: 'AuthRedirect',
      meta: { title: `${i18n.t('pageTitles.authRequest')} - KickID` },
      beforeEnter: (to, from, next) => {
        if (/* document.referrer && */!('main' in to.query)) {
          console.log('set refferer');
          checkAndSetReferrer(to);
        }

        const serviceId = localStorage.getItem('service_id') || 3;
        if ('main' in to.query) {
          const service = CONSTANTS.SERVICES_MENU.find(i => i.id === +serviceId);

          if (service) localStorage.setItem('referrer', service.url);

          delete to.query.main;
        }

        const hasSigninParam = 'signin' in to.query;
        const referrer = localStorage.getItem('referrer') || process.env.VUE_APP_KICKEX;
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        delete to.query.signin;

        if (accessToken && refreshToken) {
          store.dispatch('user/refreshToken')
            .then(async () => {
              await store.dispatch('user/getUser')
                .then(({ attributes }) => {
                  if (attributes.registration_country_id && attributes.first_name && attributes.last_name) {
                    store.dispatch('user/signinService', { referrer, serviceId, router })
                      .catch(() => {
                        next({ name: hasSigninParam ? 'signin' : 'signup', replace: true, query: to.query });
                      });
                  } else {
                    next({ name: 'complete-signup', replace: true });
                  }
                });
            });
        } else {
          next({ name: hasSigninParam ? 'signin' : 'signup', replace: true, query: to.query });
        }
      }
    },
    {
      path: '/auth/:service/:referralHash([a-zA-Z0-9]{5,32})',
      beforeEnter: (to, from, next) => {
        next({ path: `/auth/${to.params.service}`, replace: true, query: to.query });
      }
    },
    // {
    //   path: '/registration/success',
    //   name: 'registration-success',
    //   component: () => import('@/views/AfterSignupView'),
    //   props: true,
    //   meta: { title: `${i18n.t('pageTitles.signUpSuccess')} - KickID` },
    //   beforeEnter: requireAuth
    // },
    {
      path: '/activation/:key',
      name: 'activation',
      meta: { title: `${i18n.t('pageTitles.accountActivation')} - KickID` },
      beforeEnter: (to, from, next) => {
        store.dispatch('user/activateAccount', { confirmKey: to.params.key })
          .then(data => {
            if (data.id && data.access_token && data.refresh_token) {
              localStorage.setItem('access_token', data.access_token);
              localStorage.setItem('refresh_token', data.refresh_token);
              localStorage.setItem('session_id', data.group.id);

              next({ name: 'complete-signup', replace: true });
            }
          })
          .catch(() => next('/activated'));
      }
    },
    {
      path: '/activated',
      name: 'activated',
      component: () => import('@/components/AuthActivated'),
      meta: { title: `${i18n.t('pageTitles.accountAlreadyActivated')} - KickID` }
    },
    {
      path: '/social/verify/:socialName',
      name: 'social-verify',
      component: () => import('@/components/AuthSocialVerify'),
      props: true
    },
    {
      path: '/restore-confirm',
      name: 'restore-comfirm-email',
      component: () => import('@/components/AuthPasswordRestoreConfirmEmail'),
      props: true
    },
    {
      path: '/email-change',
      name: 'confirm-email-change',
      component: () => import('@/components/AuthEmailChange'),
      props: true,
      children: [
        {
          path: '/email-change/:key',
          beforeEnter: (to, from, next) => {
            const data = {
              type: 'confirm-email-change',
              attributes: { confirmKey: to.params.key }
            };

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('session_id');

            store.dispatch('user/confirmEmailChange', data)
              .catch(errors => {
                store.commit(`user/${EMAIL_CHANGE_ERRORS}`, errors);

                next({ path: '/email-change', replace: true });
              })
              .finally(() => next({ path: '/email-change', replace: true }));
          }
        }
      ]
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/components/AuthPasswordRestoreChange'),
      props: true,
      meta: { title: `${i18n.t('pageTitles.passwordChange')} - KickID` },
      children: [
        {
          path: ':key',
          beforeEnter: (to, from, next) => {
            const key = to.params.key;

            store.dispatch('user/checkResetPasswordHash', key)
              .then(() => {
                store.commit(`user/${SET_PASSWORD_KEY}`, key);
                next({ path: '/change-password', replace: true });
              })
              .catch(errors => {
                store.commit(`user/${SET_RESET_PASSWORD_HASH_ERRORS}`, errors);
                next({ path: '/restore', replace: true });
              });
          }
        }
      ]
    },
    {
      path: '/password-changed',
      name: 'confirm-password-changed',
      component: () => import('@/components/AuthPasswordChanged'),
      props: true
    },
    {
      path: '/settings',
      redirect: '/settings/personal',
      name: 'Settings',
      component: () => import('@/views/Settings'),
      beforeEnter: async (to, from, next) => {
        const sessionId = localStorage.getItem('session_id');

        if (to.query.session && sessionId) {
          if (to.query.session === sessionId) {
            next({ path: '/settings', replace: true });
          } else {
            // delete session group and logout
            await store.dispatch('user/deleteSessionGroup', sessionId);

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('session_id');
            localStorage.removeItem('referrer');

            store.commit(`user/${USER_LOGOUT}`);

            next({ name: 'signin', replace: true });
          }
        }

        next();
      },
      children: [
        {
          path: 'personal/:socialName',
          name: 'settings-social-connect',
          meta: { title: `${i18n.t('pageTitles.socialRedirect')} - KickID` },
          beforeEnter: (to, from, next) => {
            const { params: { socialName }, query: { code, state } } = to;

            if ((socialName === 'facebook' || socialName === 'google') && code) {
              const data = { access_code: code, name: socialName };

              const accessToken = localStorage.getItem('access_token');
              const refreshToken = localStorage.getItem('refresh_token');
              const accessTokenForSocialConnect = localStorage.getItem('access_token_for_social_connect');
              const storedState = localStorage.getItem('oauth_state');

              if (state !== storedState) {
                next('/');
                return;
              }

              if (accessToken && refreshToken && accessToken === accessTokenForSocialConnect) {
                store.dispatch('user/refreshToken')
                  .then(() => {
                    store.dispatch('user/connectSocial', data)
                      .then(() => {
                        store.commit(`user/${SOCIAL_CONNECT_ERRORS}`, null);

                        store.dispatch('user/getUser')
                          .catch(err => console.log('Error in getting user profile', err));
                      })
                      .catch(errors => {
                        errors.forEach(err => { err.socialName = socialName; });

                        store.commit(`user/${SOCIAL_CONNECT_ERRORS}`, errors);
                      })
                      .finally(() => {
                        localStorage.removeItem('oauth_state');
                        localStorage.removeItem('access_token_for_social_connect');
                      });
                  })
                  .finally(() => next('/settings/personal'));
              }
              next('/settings/personal');
            } else {
              next('/settings/personal');
            }
          }
        },
        {
          path: 'personal',
          name: 'settings-personal',
          component: () => import('@/views/SettingsPersonal'),
          meta: { title: `${i18n.t('pageTitles.settingsPersonal')} - KickID` },
          beforeEnter: requireAuth
        },
        {
          path: 'notifications',
          name: 'settings-notifications',
          component: () => import('@/views/SettingsNotifications'),
          meta: { title: 'Уведомления' },
          beforeEnter: requireAuth
        },
        {
          path: 'security',
          name: 'settings-security',
          component: () => import('@/views/SettingsSecurity'),
          meta: { title: `${i18n.t('pageTitles.settingsSecurity')} - KickID` },
          beforeEnter: requireAuth
        },
        {
          path: 'verification',
          name: 'settings-verification',
          component: () => import('@/views/SettingsVerification'),
          meta: { title: `${i18n.t('pageTitles.settingsVerification')} - KickID` },
          beforeEnter: requireAuth
        },
        {
          path: 'api',
          name: 'api-settings',
          component: () => import('@/views/SettingsAPI'),
          meta: { title: `${i18n.t('pageTitles.settingsAPI')} - KickID` },
          beforeEnter: requireAuth
        }
      ]
    },
    {
      path: '/redirect/:path',
      name: 'Redirect',
      component: () => import('@/views/Redirect'),
      meta: { title: `${i18n.t('pageTitles.redirect')} - KickID` }
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('@/components/Error'),
      meta: { title: `${i18n.t('pageTitles.error')} - KickID` }
    },
    {
      path: '/terms',
      name: 'Terms of Use',
      component: () => import('@/views/TermsOfUse'),
      redirect: '/terms/main',
      meta: { title: `${i18n.t('pageTitles.termsOfUse')} - KickID` },
      children: [
        {
          path: 'main',
          component: () => import('@/components/TermsOfUseMain'),
          meta: { title: 'Terms and Conditions - KickID' }
        },
        {
          path: 'appendix-1',
          component: () => import('@/components/TermsOfUseAppendix1'),
          meta: { title: 'Terms and Conditions - KickID' }
        },
        {
          path: 'appendix-2',
          component: () => import('@/components/TermsOfUseAppendix2'),
          meta: { title: 'Terms and Conditions - KickID' }
        }
      ]
    },
    {
      path: '/privacy',
      name: 'Privacy policy',
      component: () => import('@/views/PrivacyPolicy'),
      meta: { title: `${i18n.t('pageTitles.privacyPolicy')} - KickID` }
    },
    {
      path: '/data-protection',
      name: 'Data Protection Policy',
      component: () => import('@/views/DataProtection'),
      meta: { title: 'Data Protection Policy - KickID' }
    },
    {
      path: '/law-enforcement',
      name: 'Law Enforcement',
      component: () => import('@/views/LawEnforcement'),
      meta: { title: 'Law Enforcement Requests Policy - KickID' }
    },
    {
      path: '/aml-kyc',
      name: 'AML KYC',
      component: () => import('@/views/AMLandKYC'),
      meta: { title: 'Anti-Money Laundering and Know Your Customer Policy - KickID' }
    },
    {
      path: '/refund-policy',
      name: 'Refund Policy',
      component: () => import('@/views/RefundPolicy'),
      meta: { title: 'Refund Policy - KickID' }
    },
    {
      path: '/copyright',
      name: 'Copyright',
      component: () => import('@/views/Copyright'),
      meta: { title: `${i18n.t('pageTitles.copyright')} - KickID` }
    },
    {
      path: '/company',
      name: 'Company',
      component: () => import('@/views/Company'),
      meta: { title: 'Company - KickID' }
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: () => import('@/views/Contacts'),
      meta: { title: 'Contacts - KickID' }
    },
    {
      path: '*',
      name: 'not found',
      component: () => import('@/views/Page404'),
      meta: { title: `${i18n.t('pageTitles.pageNotFound')} - KickID` }
    }
  ]
});

const setReferralHash = hash => {
  if (hash) localStorage.setItem('referral_hash', hash);
};

const checkAndSetLanguage = langCode => {
  const lang = CONSTANTS.LANGUAGES.find(i => i.val === langCode);

  if (lang) {
    localStorage.setItem('language', lang.val);
    i18n.locale = lang.val;
  }
};

const setServiceId = service => {
  if (service) {
    const serviceId = CONSTANTS.SERVICES_ID[service];

    localStorage.setItem('service_id', serviceId);
  }
};

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title ? to.meta.title : 'KickID';

  // inform log
  if (process.env.NODE_ENV !== 'production') {
    console.log(`%cGoTo: ${document.title} (${to.path})`, 'color: #fff; font-weight: bold; background: rgb(70,100,100); padding:3px 5px;');
  }

  store.commit(`user/${RESOLVING_ROUTE}`, true);

  if ('lang' in to.query) {
    if (!localStorage.getItem('access_token') && !localStorage.getItem('refresh_token')) {
      checkAndSetLanguage(to.query.lang);
    }

    delete to.query.lang;

    next({ name: to.name, replace: true, query: to.query });
  }

  // check if any route has KickRef's referral hash -- to avoid redirection due to invalid session w/o this param
  setReferralHash(to.params.referralHash);

  // get and store service ID before it can be replaced due to invalid session and logout
  setServiceId(to.params.service);

  // check UTM marks in query string
  if (checkAndSetUTMParams(to.query)) {
    next({ name: to.name, replace: true, query: to.query });
  }

  return next();
});

router.afterEach(() => {
  if (store.state.user.resolvingRoute) store.commit(`user/${RESOLVING_ROUTE}`, false);
});

export default router;
