export default {
  getUser: state => state || {},
  loggedIn: state => state.loggedIn,
  welcomeImgName: state => state.welcomeImgName,
  getFacebookId: state => state.fb_id,
  getGoogleId: state => state.google_id,

  isCountryBanned: state => state.registration_country_id === 184, // russia

  buyCryptoHref: (state, getters) => {
    if (getters.isCountryBanned) {
      return 'buy-crypto';
    }

    return 'funds/buy-crypto';
  }
};
