import JsonApi from 'devour-client';
import Vue from 'vue';
import i18n from '@/dictionary';

const jsonApi = new JsonApi({
  apiUrl: `${process.env.VUE_APP_API_URL}/api/v1`
});

jsonApi.define('user', {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  avatar_url: '',
  referral_hash: '',
  slug: '',
  kyc_status: '',
  last_action_at: '',
  accepted_terms: '',
  privacy_version: '',
  terms_version: '',
  registration_country_id: '',
  language_id: '',
  captcha_id: '',
  captcha_code: '',
  created_at: '',
  updated_at: '',
  service: {
    jsonApi: 'hasOne',
    type: 'services'
  },
  utms: {
    jsonApi: 'hasMany',
    type: 'utms'
  }
});

jsonApi.define('utm', {
  name: '',
  value: ''
});

jsonApi.define('session', {
  email: '',
  password: '',
  captcha_id: '',
  captcha_code: '',
  access_token: '',
  refresh_token: '',
  group: {
    jsonApi: 'hasOne',
    type: 'session-groups'
  }
});

jsonApi.define('login-token', {
  login_token: '',
  '2fa_type': ''
});

jsonApi.define('service-sessions', {
  serviceId: ''
});

jsonApi.define('user-session', {
  session_hash: '',
  ip: '',
  device: '',
  country: '',
  city: '',
  browser: '',
  created_at: '',
  service: {
    jsonApi: 'hasOne',
    type: 'service'
  },
  group: {
    jsonApi: 'hasOne',
    type: 'session-groups'
  }
});

jsonApi.define('session-group', {
  id: ''
});

jsonApi.define('service', {
  id: '',
  name: ''
});

jsonApi.define('refresh-tokens', {
  refresh_token: ''
});

jsonApi.define('social-redirect-url', {
  name: '',
  url: '',
  type: ''
});

jsonApi.define('social-network', {
  access_code: '',
  name: '',
  referral_hash: '',
  language_id: '',
  service: {
    jsonApi: 'hasOne',
    type: 'services'
  }
});

jsonApi.define('recovery-passwords', {
  email: ''
});

jsonApi.define('confirm-key', {
  confirmKey: ''
});

jsonApi.define('kyc-country', {
  name: '',
  code: ''
});

jsonApi.define('country', {
  name: '',
  code: '',
  lang_id: '',
  lang: '',
  alpha_3_code: '',
  is_country_high_risk: '',
  is_country_restricted: '',
  deleted_at: ''
});

jsonApi.define('language', {
  code: ''
});

jsonApi.define('kyc-document-type', {
  name: ''
});

jsonApi.define('notification-setting', {
  platform_id: '',
  notify_name: '',
  is_internal_enabled: '',
  is_phone_enabled: '',
  is_email_enabled: ''
});

jsonApi.define('2fa-secret', {
  id: ''
});

jsonApi.define('captcha-id', {
  id: ''
});

jsonApi.define('kyc-session', {
  id: '',
  applicant_id: '',
  external_user_id: '',
  token: '',
  client_id: ''
});

jsonApi.define('access-token-SDK', {
  // id: '',
  // applicant_id: '',
  // external_user_id: '',
  token: ''
  // client_id: ''
});

jsonApi.define('api-key', {
  name: '',
  api_key: '',
  api_secret: '',
  date_start: '',
  date_end: '',
  ip: '',
  rights: '',
  is_blocked: '',
  is_deleted: '',
  platform_id: '',
  '2fa_code': '',
  passphrase: ''
});

// add headers to requests to be sent through Devour client
const requestMiddleware = {
  name: 'add-headers',
  req: payload => {
    payload.jsonApi.axios.defaults.timeout = 0;

    payload.req.headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json'
    };

    if (document.cookie) {
      payload.req.headers['X-Cookie'] = document.cookie;
    }

    const accessToken = localStorage.getItem('access_token');

    if (accessToken && accessToken !== 'undefined') { payload.req.headers.Authorization = `Bearer ${accessToken}`; }

    return payload;
  }
};

const replaceAttributeInError = (attrArr, error, errorPropName) => {
  attrArr.forEach(item => {
    const attrValue = error.source.parameter.slice(error.source.parameter.lastIndexOf('.') + 1);

    error[errorPropName] = error[errorPropName].replace(item.trim(), attrValue);
  });
};

const replaceParamsInError = (paramsArr, error, errorPropName) => {
  paramsArr.forEach((attr, index) => {
    // type of error.meta.parameters can be Object or Array
    if (Array.isArray(error.meta.parameters)) {
      error[errorPropName] = error[errorPropName].replace(attr.trim(), error.meta.parameters[index]);
    }

    if (typeof error.meta.parameters === 'object') {
      const objKey = attr.slice(2);

      error[errorPropName] = error[errorPropName].replace(attr.trim(), error.meta.parameters[objKey]);
    }
  });
};

const replacePlaceholdersInError = (placeholdersArr, error, errorPropName) => {
  const attrs = placeholdersArr.filter(i => i.trim() === ':attribute');
  const params = placeholdersArr.filter(i => i.trim() !== ':attribute');

  if (attrs.length) replaceAttributeInError(attrs, error, errorPropName);

  if (params.length) replaceParamsInError(params, error, errorPropName);
};

// get :placeholders from errors' titles and details and replace them with their values from error meta fields
const transformValidationErrors = errorsArr => errorsArr.map(error => {
  if (error.code) {
    const translatedError = {
      code: error.code,
      title: i18n.te(`errorCodes.${error.code}.title`) ? i18n.t(`errorCodes.${error.code}.title`) : error.title,
      detail: i18n.te(`errorCodes.${error.code}.detail`) ? i18n.t(`errorCodes.${error.code}.detail`) : error.detail
    };

    return translatedError;
  }

  const titleMatches = error.title.match(/\s:[a-zA-Z0-9]+/g);
  const detailMatches = error.detail.match(/\s:[a-zA-Z0-9]+/g);

  if (titleMatches) replacePlaceholdersInError(titleMatches, error, 'title');
  if (detailMatches) replacePlaceholdersInError(detailMatches, error, 'detail');

  return error;
});

const checkIfCaptchaRequested = errorsArr => errorsArr.some(error => error.source && error.source.parameter && error.source.parameter.includes('captcha_id'));

const errorMiddleware = {
  name: 'custom-errors',
  error(payload) {
    // if (payload.response && payload.response.status === 400) return payload.response.data.errors;

    if (payload.response && [400, 422].includes(payload.response.status) && payload.response.data.errors.length && checkIfCaptchaRequested(payload.response.data.errors)) {
      Vue.prototype.$eventHub.$emit('captchaRequested');

      return [];
    }

    if (payload.response && payload.response.data && payload.response.data.errors.length) {
      return transformValidationErrors(payload.response.data.errors);
    }

    return payload;
  }
};

jsonApi.insertMiddlewareBefore('axios-request', requestMiddleware);
jsonApi.replaceMiddleware('errors', errorMiddleware);

export default jsonApi;
