export const currency = (str, locale = 'en') => (+str).toLocaleString(locale, { maximumFractionDigits: 10 });

export const session = (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
  });
  if (result !== 'Invalid Date') return result;
  return '';
};

export const socialConnect = (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'short', day: 'numeric'
  });
  if (result !== 'Invalid Date') return result;
  return '';
};

export const updatedLegal = (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'numeric', day: 'numeric'
  });
  if (result !== 'Invalid Date') return result;
  return '';
};

export const messageTime = (date, locale = 'en') => {
  const result = new Date(date).toLocaleTimeString(locale, {
    hour: 'numeric', minute: 'numeric', hour12: true
  });
  if (result !== 'Invalid Date') return result;
  return '';
};
