<template lang="pug">
  div
    .form_policy(align-center)
      v-checkbox(
        :value="terms",
        :rules="[rules.required]",
        @change="$emit('update:terms', $event)")
        template(v-slot:label="")
          span.policy_agreement(ref="label1", v-html="$t('hints.agreeToTerms')")
    .form_policy(align-center)
      v-checkbox(
        :value="policy",
        :rules="[rules.required]",
        @change="$emit('update:policy', $event)")
        template(v-slot:label="")
          span.policy_agreement(ref="label2", v-html="$t('hints.agreeToPrivacyPolicy')")
</template>

<script>
export default {
  props: {
    terms: { type: Boolean, default: false },
    policy: { type: Boolean, default: false }
  },
  components: {
  },
  computed: {
    rules() {
      return {
        required: v => !!v || this.$t('validation.required')
      };
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      setTimeout(this.stopLinksClickEventPropagation, 0);
    }
  },
  methods: {
    stopLinksClickEventPropagation() {
      const link1 = this.$refs.label1.querySelector('a');
      const link2 = this.$refs.label2.querySelector('a');

      link1.addEventListener('click', ev => ev.stopPropagation());
      link2.addEventListener('click', ev => ev.stopPropagation());
    }
  },
  mounted() {
    setTimeout(this.stopLinksClickEventPropagation, 500);
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .form_policy {
    min-height: 40px;
    position: relative;
    font-weight: 400;
    color: $black3;

    ::v-deep .v-label {
      color: $black3 !important;
      font-weight: 400 !important;
    }

    ::v-deep .v-input__slot { background: transparent !important; }

    ::v-deep .v-input--checkbox > .v-input__control > .v-input__slot > .v-input--selection-controls__input {
      &::before {
        background: $white !important;
        border: 1px solid $light-theme-background !important;
      }

      &::after {
        background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg"><path style="fill: rgb(12, 196, 102);" d="M7.7,17.9l-1.5-1.4c-0.2-0.2-4-3.9-5.8-6.1l3.1-2.6c1.1,1.3,2.9,3.1,4.1,4.4L18.5,0.6l2.9,2.7L7.7,17.9z"/></svg>');
      }
    }

    &:last-of-type { margin-bottom: 10px !important; }
  }
</style>
