export const SET_USER = 'SET_USER';
export const RECOVERY_ERROR = 'RECOVERY_ERROR';
export const RECOVERY_SENT = 'RECOVERY_SENT';
export const ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const SET_PASSWORD_KEY = 'SET_PASSWORD_KEY';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const USER_LOGOUT = 'USER_LOGOUT';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const RESOLVING_ROUTE = 'RESOLVING_ROUTE';
export const SET_WS_STATE = 'SET_WS_STATE';
export const SET_WS_AUTH = 'SET_WS_AUTH';
export const SET_WS_UNREAD_NOTIFICATIONS = 'SET_WS_UNREAD_NOTIFICATIONS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const CONFIRMATION_RESENT = 'CONFIRMATION_RESENT';
export const EMAIL_CHANGE_ERRORS = 'EMAIL_CHANGE_ERRORS';
export const EMAIL_CHANGE_SUCCESS = 'EMAIL_CHANGE_SUCCESS';
export const SIGNIN_CAPTCHA_REQUIRED = 'SIGNIN_CAPTCHA_REQUIRED';
export const SET_CAPTCHA_ID = 'SET_CAPTCHA_ID';
export const SET_CAPTCHA_IMAGE = 'SET_CAPTCHA_IMAGE';
export const SOCIAL_CONNECT_ERRORS = 'SOCIAL_CONNECT_ERRORS';
export const SOCIAL_SIGNIN_ERRORS = 'SOCIAL_SIGNIN_ERRORS';
export const SET_RESET_PASSWORD_HASH_ERRORS = 'SET_RESET_PASSWORD_HASH_ERRORS';
export const KYC_SESSION_ERRORS = 'KYC_SESSION_ERRORS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_TERMS_LATEST_VERSIONS = 'SET_TERMS_LATEST_VERSIONS';
export const SET_TERMS_ACCEPTANCE_STATUS = 'SET_TERMS_ACCEPTANCE_STATUS';
export const SET_REDIRECT_URL_KYC = 'SET_REDIRECT_URL_KYC';
export const SET_IMG_NAME = 'SET_IMG_NAME';
