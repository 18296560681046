import Vue from 'vue';

let reconnectionAttempts = 0;

const WS = {};

const reconnect = () => {
  const timeout = (reconnectionAttempts ** 2) * 5000;

  setTimeout(WS.init, timeout);
};

const initialState = {
  socket: null,
  init() {
    this.socket = new WebSocket(process.env.VUE_APP_KICKNOTIFY_WS);

    this.socket.onopen = () => {
      Vue.prototype.$eventHub.$emit('WSopened');
      reconnectionAttempts = 0;
    };

    this.socket.onmessage = event => {
      const data = JSON.parse(event.data);

      if (data.type === 'notifies' && data.notifies.length) {
        let interval = 500;

        data.notifies.forEach(item => {
          interval += 500;
          setTimeout(this.showNotification.bind(this, item), interval);
        });
      }

      if (data.type === 'message_count') {
        Vue.prototype.$eventHub.$emit('WSunreadMessages', data.count);
      }
    };

    this.socket.onclose = event => {
      if (event.wasClean) {
        console.log(`Notify WS closed, code=${event.code} reason=${event.reason}`);
      } else {
        console.warn('Notify WS сonnection died! Reconnection attempt in', (reconnectionAttempts ** 2) * 5, 'sec');
        reconnect();
        reconnectionAttempts++;
      }

      Vue.prototype.$eventHub.$emit('notifyWSclosed');
    };

    this.socket.onerror = error => {
      console.error(`Notify WS error: ${JSON.stringify(error, ['message', 'type', 'name'])}`);
      this.socket.close();
    };
  },
  send(data) {
    if (this.socket && this.socket.readyState === 1) {
      this.socket.send(data);
    }
  },
  authenticate(token) {
    const data = JSON.stringify({
      type: 'authentication',
      access_token: token
    });

    this.send(data);
  },
  showNotification(item) {
    const expiry = 5000;

    Vue.notify({
      group: 'kick',
      title: item.title,
      text: item.text,
      duration: expiry,
      data: {
        id: item.id,
        created: item.created,
        type: item.type,
        payload: item.payload
      }
    });
  },
  readNotification(id) {
    const data = JSON.stringify({ type: 'read_notify', id });

    this.send(data);
  },
  close(code, reason) {
    if (this.socket) this.socket.close(code, reason);
  }
};

Object.assign(WS, initialState);

export default WS;
