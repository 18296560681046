import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 0,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json'
  }
});

instance.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('access_token');

  if (accessToken && accessToken !== 'undefined') {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (document.cookie) {
    config.headers['X-Cookie'] = document.cookie;
  }

  return config;
});

export default instance;
