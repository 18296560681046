// import * as Sentry from '@sentry/browser';
import axios from '@/axios';
import jsonApi from '@/jsonApi';
import {
  SET_USER,
  RECOVERY_ERROR,
  RECOVERY_SENT,
  ACCOUNT_ACTIVATED,
  ACTIVATION_ERROR,
  PASSWORD_CHANGED,
  PASSWORD_CHANGE_ERROR,
  PROFILE_LOADING,
  USER_LOGOUT,
  REFRESHING_TOKEN,
  CONFIRMATION_RESENT,
  SET_CAPTCHA_ID,
  SET_CAPTCHA_IMAGE,
  KYC_SESSION_ERRORS,
  SET_COUNTRIES,
  SET_TERMS_LATEST_VERSIONS,
  SET_TERMS_ACCEPTANCE_STATUS,
  SET_IMG_NAME
} from '@/store/modules/user/types';
// import { getUnauthUserLocale } from '@/utils';
import CONSTANTS from '@/constants';

const addUTM = payload => {
  if (!payload.data.relationships) {
    payload.data.relationships = {
      utms: { data: [] }
    };
  } else {
    payload.data.relationships.utms = { data: [] };
  }

  CONSTANTS.UTM_PARAMS.forEach(key => {
    if (localStorage.getItem(key)) {
      payload.data.relationships.utms.data.push({
        type: 'utms',
        name: key,
        value: localStorage.getItem(key)
      });
    }
  });
};
const deleteUTM = () => {
  CONSTANTS.UTM_PARAMS.forEach(key => {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  });
};
export default {
  // get user's profile
  getUser: ({ state, commit, dispatch }) => new Promise(resolve => {
    commit(PROFILE_LOADING);

    axios.get('/api/v1/user-profile-internal')
      .then(response => {
        commit(SET_USER, response.data.data);

        // Sentry.configureScope(scope => scope.setUser({ id: response.data.data.id }));

        if (state.latestVersionTerms) {
          commit(SET_TERMS_ACCEPTANCE_STATUS, state.latestVersionTerms.version === state.terms_version && state.latestVersionPolicy.version === state.privacy_version);
          resolve(response.data.data);
        } else {
          dispatch('getLatestTermsVersion')
            .then(({ data }) => {
              const terms = data.find(i => i.type === 'terms-versions');
              const policy = data.find(i => i.type === 'privacy-versions');
              const payload = {
                terms: terms.attributes,
                policy: policy.attributes
              };

              commit(SET_TERMS_LATEST_VERSIONS, payload);
              commit(SET_TERMS_ACCEPTANCE_STATUS, state.latestVersionTerms.version === state.terms_version && state.latestVersionPolicy.version === state.privacy_version);

              resolve(response.data.data);
            })
            .catch(err => console.log(err));
        }
      })
      .catch(error => console.log('Error in getting user\'s profile:', error))
      .finally(() => commit(PROFILE_LOADING));
  }),
  // get all countries for select in KYC form
  getKycContries: () => new Promise((resolve, reject) => {
    jsonApi.findAll('kyc-country')
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  }),
  getAllCountries: ({ commit }) => new Promise((resolve, reject) => {
    jsonApi.findAll('country')
      .then(({ data }) => commit(SET_COUNTRIES, data))
      .catch(error => reject(error));
  }),
  getRestrictedCountries: () => new Promise((resolve, reject) => {
    jsonApi.findAll('country', { 'filter[restricted]': true })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error));
  }),
  getLanguageList: () => new Promise((resolve, reject) => {
    jsonApi.findAll('language')
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  }),
  // get document types for select in KYC form
  getDocTypes: () => new Promise((resolve, reject) => {
    jsonApi.findAll('kyc-document-type')
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  }),
  submitKYCDocs: (context, data) => new Promise((resolve, reject) => {
    axios.post('/api/v1/kyc-verification', data)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  // store new user data and get Kick Auth tokens for further sign in
  signup: (context, user) => new Promise((resolve, reject) => {
    addUTM(user);

    axios.post('/api/v1/users', user)
      .then(({ data: { data } }) => {
        localStorage.setItem('access_token', data.attributes.access_token);
        localStorage.setItem('refresh_token', data.attributes.refresh_token);
        localStorage.setItem('session_id', data.relationships.group.data.id);
        deleteUTM();
        resolve();
      })
      .catch(errors => reject(errors));
  }),
  // get Kick Auth tokens for users signing in via social media
  signinSocial: (context, payload) => new Promise((resolve, reject) => {
    addUTM(payload);

    axios.post('/api/v1/social-networks', payload)
      .then(({ data: { data } }) => {
        if (data.attributes.access_token && data.attributes.refresh_token && data.relationships.group.data.id) {
          localStorage.setItem('access_token', data.attributes.access_token);
          localStorage.setItem('refresh_token', data.attributes.refresh_token);
          localStorage.setItem('session_id', data.relationships.group.data.id);
        }
        deleteUTM();
        resolve(data.attributes);
      })
      .catch(errors => reject(errors));
  }),
  // get Kick Auth tokens for users signing in via email
  signinByEmail: (context, payload) => new Promise((resolve, reject) => {
    addUTM(payload);

    axios.post('/api/v1/sessions', payload)
      .then(({ data: { data } }) => {
        if (data.attributes.access_token && data.attributes.refresh_token && data.relationships.group.data.id) {
          localStorage.setItem('session_id', data.relationships.group.data.id);
          localStorage.setItem('access_token', data.attributes.access_token);
          localStorage.setItem('refresh_token', data.attributes.refresh_token);
        }
        deleteUTM();

        resolve(data);
      })
      .catch(errors => reject(errors));
  }),
  // confirm 2FA auth
  confirm2fa: (context, payload) => new Promise((resolve, reject) => {
    axios.post('/api/v1/confirm-2-fa', payload)
      .then(({ data: { data } }) => {
        localStorage.setItem('access_token', data.attributes.access_token);
        localStorage.setItem('refresh_token', data.attributes.refresh_token);
        localStorage.setItem('session_id', data.relationships.group.data.id);

        resolve();
      })
      .catch(errors => reject(errors));
  }),
  // sign in service requesting auth and redirect user to it
  signinService: (store, { serviceId, redirectUrl, referralHash }) => new Promise((resolve, reject) => {
    const referrer = redirectUrl || localStorage.getItem('referrer') || process.env.VUE_APP_KICKEX;
    const requestServiceID = +serviceId || +localStorage.getItem('service_id') || 3;

    if (serviceId === '0' && localStorage.getItem('referrer')) {
      window.location = `${localStorage.getItem('referrer')}?accessToken=${localStorage.getItem('access_token')}&refreshToken=${localStorage.getItem('refresh_token')}&session=${localStorage.getItem('session_id')}`;
    }

    jsonApi.all('service-sessions').post({ serviceId: Math.abs(requestServiceID) })
      .then(({ data }) => {
        if (data && data.access_token && data.refresh_token) {
          const accessToken = data.access_token;
          const refreshToken = data.refresh_token;
          const sessionGroupId = data.group.id;
          const separator = referrer.indexOf('?') < 0 ? '?' : '&';

          console.log('requestServiceID', requestServiceID);

          let url = `${referrer}${separator}accessToken=${accessToken}&refreshToken=${refreshToken}&session=${sessionGroupId}`;

          // kickex
          if (requestServiceID === 3) {
            const fromUrl = sessionStorage.getItem('fromUrl') || process.env.VUE_APP_KICKEX;
            const fromUrlHost = new URL(fromUrl).origin;
            url = `${fromUrlHost}/gate${separator}redirectUrl=${referrer}&accessToken=${accessToken}&refreshToken=${refreshToken}&session=${sessionGroupId}`;
          }

          console.log(url);
          // kickref
          if (referralHash && requestServiceID === 4) {
            url = `${url}&reg=kickref`;
            localStorage.removeItem('referral_hash');
          }

          window.location = url;
          // router.push({ name: 'Redirect', params: { path: url } });
        }
      })
      .catch(errors => reject(errors));
  }),
  // refresh Kick Auth access token
  refreshToken: store => new Promise(resolve => {
    if (store.state.refreshingToken) return;

    store.commit(REFRESHING_TOKEN);

    jsonApi.all('refresh-tokens').post({ refresh_token: localStorage.getItem('refresh_token') })
      .then(({ data }) => {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('session_id', data.group.id);
        resolve(data.access_token);
      })
      .catch(() => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('session_id');

        store.commit(USER_LOGOUT);

        try {
          if (!['auth', 'signup', 'signin'].includes(this.$route.name)) {
            window.location.replace(`/signin${window.location.search}`);
          }
        } catch (e) {
          window.location.replace('/signin');
        }
      })
      .finally(() => store.commit(REFRESHING_TOKEN));
  }),
  // get social redirect URLs depending on user's preferred social media
  getSocialRedirectUrls: (context, { socialName, type }) => new Promise((resolve, reject) => {
    jsonApi.findAll('social-redirect-url')
      .then(response => {
        response.data.forEach(item => { if (item.name === socialName && item.type === type) resolve(item.url); });
        // response.data.forEach(item => {
        //   if (item.name === socialName && item.type === type) {
        //     window.location = item.url;
        //     // router.push({ name: 'Redirect', params: { path: item.url } });
        //   }
        // });
      })
      .catch(errors => reject(errors));
  }),
  // connect social networks for users with verified emails
  connectSocial: (store, data) => new Promise((resolve, reject) => {
    jsonApi.all('social-network').post(data)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  // request password recovery through email
  sendRecoveryEmail: ({ commit }, data) => new Promise((resolve, reject) => {
    const { data: { attributes: { email } } } = data;

    axios.post(`${process.env.VUE_APP_API_URL}/api/v1/recovery-passwords`, data)
      .then(() => {
        resolve();
        commit(RECOVERY_SENT, email);
      })
      .catch(errors => {
        reject(errors);
        commit(RECOVERY_ERROR);
      });
  }),
  // reset password with code received in email
  resetPassword: ({ commit }, data) => new Promise((resolve, reject) => {
    axios.post(`${process.env.VUE_APP_API_URL}/api/v1/reset-passwords`, data)
      .then(response => {
        if ([200, 204].includes(response.status)) {
          commit(PASSWORD_CHANGED);
          resolve();
        }
      })
      .catch(errors => {
        reject(errors);
        commit(PASSWORD_CHANGE_ERROR, errors);
      });
  }),
  // update user's password with the current password
  updatePassword: ({ commit }, data) => new Promise((resolve, reject) => {
    axios.post(`${process.env.VUE_APP_API_URL}/api/v1/update-passwords`, data)
      .then(response => {
        if ([200, 204].includes(response.status)) {
          commit(PASSWORD_CHANGED);
          resolve();
        }
      })
      .catch(errors => {
        reject(errors);
        commit(PASSWORD_CHANGE_ERROR, errors);
      });
  }),
  // activate account with code received in email
  activateAccount: ({ commit }, data) => new Promise((resolve, reject) => {
    jsonApi.all('confirm-key').post(data)
      .then(res => {
        commit(ACCOUNT_ACTIVATED);
        resolve(res.data);
      })
      .catch(errors => {
        commit(ACTIVATION_ERROR, errors);
        reject(errors);
      });
  }),
  // get 2FA secret code
  get2FAsecret: () => new Promise((resolve, reject) => {
    jsonApi.findAll('2fa-secret')
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  // toggle 2FA status
  toggle2FA: (store, payload) => new Promise((resolve, reject) => {
    axios.patch('/api/v1/toggle-2fa', payload)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  // get all user's sessions
  getUserSessions: () => new Promise((resolve, reject) => {
    jsonApi.findAll('user-session')
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  // delete user's session group
  deleteSessionGroup: (store, groupId) => new Promise((resolve, reject) => {
    jsonApi.destroy('session-group', groupId)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  // update user's profile
  updateProfile: (store, data) => new Promise((resolve, reject) => {
    axios.patch('/api/v1/user-profile-internal', data)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  // fetch user's notifications settings
  getNotificationsSettings: () => new Promise((resolve, reject) => {
    jsonApi.findAll('notification-setting')
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  updateSettings: (store, payload) => new Promise((resolve, reject) => {
    jsonApi.all('notification-setting').patch(payload)
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  resendConfirmationEmail: ({ commit }) => new Promise((resolve, reject) => {
    axios.get('/api/v1/resend-confirmation-email')
      .then(() => {
        commit(CONFIRMATION_RESENT);
        resolve();
      })
      .catch(errors => reject(errors));
  }),
  confirmEmailChange: (store, data) => new Promise((resolve, reject) => {
    axios.post('/api/v1/confirm-email-change', { data })
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  getCaptchaId: ({ commit }) => new Promise((resolve, reject) => {
    jsonApi.findAll('captcha-id')
      .then(({ data: { id } }) => {
        commit(SET_CAPTCHA_ID, id);
        resolve();
      })
      .catch(errors => reject(errors));
  }),
  getCaptchaImage: ({ commit }, id) => new Promise((resolve, reject) => {
    axios.get('/api/v1/captcha', { params: { id } })
      .then(({ data: { data: { attributes: { image } } } }) => {
        commit(SET_CAPTCHA_IMAGE, image);
        resolve();
      })
      .catch(errors => reject(errors));
  }),
  getKYCSession: ({ commit }) => new Promise((resolve, reject) => {
    jsonApi.findAll('kyc-session')
      .then(res => {
        commit(KYC_SESSION_ERRORS, null);
        resolve(res.data);
      })
      .catch(errors => {
        commit(KYC_SESSION_ERRORS, errors);
        reject();
      });
  }),
  checkResetPasswordHash: (store, hash) => new Promise((resolve, reject) => {
    axios.get(`/api/v1/reset-password-check/${hash}`)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  getAPIKeys: (store, params) => new Promise((resolve, reject) => {
    jsonApi.findAll('api-key', params)
      .then(res => resolve(res))
      .catch(errors => reject(errors));
  }),
  createApiKey: (store, payload) => new Promise((resolve, reject) => {
    jsonApi.create('api-key', payload)
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  validateApiKeyCreation: (store, attributes) => new Promise((resolve, reject) => {
    axios.post('/api/v1/api-keys/validate', { data: { type: 'api-keys', attributes } })
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  deleteApiKey: (store, key) => new Promise((resolve, reject) => {
    jsonApi.destroy('api-key', key)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  deleteAllApiKeys: () => new Promise((resolve, reject) => {
    axios.delete('/api/v1/api-keys')
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  validateApiKeyUpdate: (store, key) => new Promise((resolve, reject) => {
    axios.patch(`/api/v1/api-keys/${key.id}/validate`, {
      data: {
        type: 'api-keys',
        attributes: key
      }
    })
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  updateApiKey: (store, key) => new Promise((resolve, reject) => {
    jsonApi.update('api-key', key)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  getLatestTermsVersion: () => new Promise((resolve, reject) => {
    axios.get('/api/v1/latest-terms-privacy')
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  getCountryCodeByIP: () => new Promise((resolve, reject) => {
    axios.get('/api/v1/country')
      .then(({ data }) => resolve(data))
      .catch(errors => reject(errors));
  }),
  blockAccount: (store, code) => new Promise((resolve, reject) => {
    axios.get(`/api/v1/users/block-codes/${code}`)
      .then(() => resolve())
      .catch(errors => reject(errors));
  }),
  getEmailPin: (store, token = null) => new Promise((resolve, reject) => {
    const payload = {
      data: { type: 'email-codes' }
    };

    if (token) {
      payload.data.attributes = { login_token: token };
    }

    axios.post('/api/v1/users/email-codes', payload)
      .then(resolve)
      .catch(errors => reject(errors));
  }),
  setImgName({ commit }, name) {
    commit(SET_IMG_NAME, name);
  }
};
