import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/dictionary/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set locale
  messages: { en }
});

// https://kazupon.github.io/vue-i18n/ru/guide/lazy-loading.html

async function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

const loadedLanguages = ['en']; // список локализаций, которые пред-загружены

async function messages(lang) {
  // Если локализация ещё не была загружена
  const message = await import('./' + lang + '.json'); // eslint-disable-line prefer-template
  i18n.setLocaleMessage(lang, message);
  loadedLanguages.push(lang);
}

export async function loadLanguageAsync(lang) {
  // Если локализация та же или локализация уже была загружена
  if (i18n.locale === lang || loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  await messages(lang);
  return setI18nLanguage(lang);
}
export default i18n;
